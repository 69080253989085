import "common/styles/globals.css"
import "common/styles/fonts.css"
import { PagePropsContext } from "common/contexts/pageProps"
import type { AppPropsWithLayout } from "common/types/page"
import { MetaAttributes } from "common/components/MetaAttributes"
import { BugsnagErrorBoundary } from "common/components/BugsnagErrorBoundary"
import { ClientRenderingError } from "common/components/ClientRenderingError"
import {
  NotificationsProvider,
  NotificationsContainer,
  useNotificationsFromQueryStrings,
} from "common/components/Notification"
import type { AppProps } from "common/lib/getAppServerSideProps"
import { useEffect } from "react"
import { FontsPreload } from "common/components/FontsPreload"
import { Didomi } from "common/components/Didomi"
import { SmartOcto } from "common/components/SmartOcto"
import { GoogleTagManager } from "common/components/GoogleTagManager"
import { Echobox } from "common/components/Echobox"
import { AmplitudeContextStateContainer } from "common/components/AmplitudeContextStateContainer/AmplitudeContextStateContainer"
import { AmplitudeAnalytics } from "common/components/AmplitudeAnalytics"

function CustomApp({ Component, pageProps }: AppPropsWithLayout<AppProps>) {
  const getLayout = Component.getLayout || ((page) => page)
  const locale = pageProps?.locale
  const initialNotifications = useNotificationsFromQueryStrings()

  // Unfortunately by disabling default locale support in NextJS (because our routing is not that straightforward),
  // NextJS won't set "lang" attribute on <html> element. We also can't set that attribute by ourselves on server side
  // dynamically (by given locale from `getServerSideProps`). As a result we have to set "lang" attribute on the client side,
  // which from accessibility perspective it does the job.
  useEffect(() => {
    if (locale) {
      document.documentElement.lang = locale
    }
  }, [locale])

  return (
    // FallbackComponent for BugsnagErrorBoundary helps us mitigate in an elegant way NextJS hydration bug on error pages.
    // More in the following PR description: https://github.com/conversation/tc/pull/12410.
    <BugsnagErrorBoundary FallbackComponent={ClientRenderingError}>
      <MetaAttributes
        ogTitle="The Conversation: In-depth analysis, research, news and ideas from leading academics and researchers."
        ogDescription="Curated by professional editors, The Conversation offers informed commentary and debate on the issues affecting our world. Plus a Plain English guide to the latest developments and discoveries from the university and research sector."
        siteName="The Conversation"
        articlePublisher="https://facebook.com/ConversationEDU"
      />
      <FontsPreload />
      <NotificationsProvider value={initialNotifications}>
        <PagePropsContext.Provider value={pageProps}>
          <GoogleTagManager />
          <Didomi />
          <SmartOcto />
          <Echobox />
          <AmplitudeContextStateContainer regionCode={pageProps.regionCode}>
            <AmplitudeAnalytics />
            {getLayout(<Component {...pageProps} />, pageProps)}
          </AmplitudeContextStateContainer>
          <NotificationsContainer />
        </PagePropsContext.Provider>
      </NotificationsProvider>
    </BugsnagErrorBoundary>
  )
}

export default CustomApp
