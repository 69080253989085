import { Ampli } from "common/lib/ampli"
import { useEffect, useContext } from "react"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { usePageProps } from "common/hooks/data/usePageProps"
import { ViewPageProps } from "pages/view/index.page"
import { AmplitudeContext } from "common/contexts/AmplitudeContext"

/**
 * AmplitudeAnalytics triggers a Page Viewed event to be sent to
 * Amplitude. It only tries to do this if we have initialised the
 * client, and if the pageType is defined, which avoids firing this
 * event off prematurely.
 */
export const AmplitudeAnalytics = () => {
  const { pageType, regionCode } = usePageProps<ViewPageProps>()
  const ampliContext = useContext(AmplitudeContext)

  useEffect(() => {
    if (ampliContext.isLoaded && pageType != undefined) {
      trackPageView(ampliContext, regionCode, pageType, window.location.pathname)
    }
  }, [ampliContext, pageType, regionCode])

  return <></>
}

/**
 * Queues up a page view tracking event to be sent via the Amplitude client.
 *
 * This function is quite sparse -- originally it was responsible for fetching the
 * parameters from various PageProp contexts, but that responsbilityy was
 * eventually lifted out to higher levels of the code.
 */
export function trackPageView(client: Ampli, edition: string, pageType: string, pathName: string) {
  const event = {
    app: "cms",
    edition: edition,
    page_type: pageType,
    path: pathName,
  }

  client.pageViewed(event)
}
