import { useGraphqlQuery } from "./useGraphqlQuery"
import { Query, RoleEnum, Viewer } from "common/types/graphql"
import { gql } from "common/lib/gql"
import { useContext, createContext } from "react"

export const VIEWER_QUERY = gql`
  query {
    viewer {
      role
      user {
        id
        name
        avatar
        commentNotificationCount
        allow {
          analytics
          newHeader
          moderateComments
        }
        newsFeedCount
        donations {
          lastDonated
          lastRegionDonatedTo
          lastDonationRecurring
          numDonations
          numDonationsThisYear
          totalAmount
          totalAmountThisYear
        }
      }
    }
  }
`

export const TestViewerContext = createContext<Pick<Viewer, "role" | "user"> | undefined>(undefined)

/**
 * Data hook to return the current viewer.
 * This hook also implements helper attributes to determine viewer roles.
 */
export const useViewer = () => {
  const { data } = useGraphqlQuery<Query>(VIEWER_QUERY)
  const testViewer = useContext(TestViewerContext)
  const viewer = testViewer || data?.viewer

  return {
    viewer,
    isEditor: viewer?.role === RoleEnum.Editor,
    isAuthor: viewer?.role === RoleEnum.Author,
    isReader: viewer?.role === RoleEnum.Reader,
  }
}
