import { Ampli, ampli, PromiseResult } from "common/lib/ampli"
import getConfig from "next/config"

/**
 * Builds a genuine Ampli client, using an API key.
 * The result of this function could be passed as a value prop for AmplitudeContext.Provider.
 *
 * @param {string} apiKey An Amplitude API key
 */
function buildAmpliClient(apiKey: string): Ampli {
  ampli.load({
    client: {
      apiKey: apiKey,
      configuration: {
        defaultTracking: {
          attribution: {
            // If left on default, Amplitude excludes attribution coming from referrers
            // that match your own top-level domain. (ie. from theconversation to donate.)
            // We set this to empty to include all sources.
            excludeReferrers: [],
            resetSessionOnNewCampaign: true,
          },
          sessions: true,
          pageViews: false,
          formInteractions: false,
          fileDownloads: false,
        },
      },
    },
  })
  return ampli
}

/**
 * Generate a fake no-op client, which silently discards events.
 * This is used when we are in a region where we don't want to use
 * Amplitude (eg. due to cookie consent requirements), or when
 * Amplitude is not enabled at all.
 */
export function buildNoOpClient(): Ampli {
  function defaultClientWarning(functionName: string) {
    if (!window.location.hostname.endsWith("theconversation.com")) {
      console.log(`Stub Ampli client called with ${functionName}()`)
    }
    return ""
  }

  var fakeClient = new Ampli()

  // Override the track() method with our version, as the original will
  // complain to console.log if called when uninitialised as we'd rather it
  // was silent.
  fakeClient.track = (event, options): PromiseResult => {
    defaultClientWarning("track")
    return { promise: Promise.resolve() }
  }

  return fakeClient
}

/**
 * Build an Amplitude client -- either a real one, if we're in a suitable
 * region, or a fake no-op client. (Currently this only loads a real client
 * in the NZ edition's region)
 */
export function amplitudeClient(region: string) {
  const amplitudeEnabled = getConfig().publicRuntimeConfig.AMPLITUDE_ENABLED == "true"
  const amplitudeApiKey = getConfig().publicRuntimeConfig.AMPLITUDE_BROWSER_API_KEY
  const trulyEnabled = amplitudeEnabled && region == "nz"

  if (trulyEnabled) {
    return buildAmpliClient(amplitudeApiKey)
  } else {
    return buildNoOpClient()
  }
}
