import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid"
import { Transition } from "@headlessui/react"
import { nanoid } from "nanoid"
import { Heading } from "common/components/Heading"
import clsx from "classnames"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useTimeoutEffect } from "common/hooks/interaction/useTimeoutEffect"
import {
  useState,
  useCallback,
  useMemo,
  useContext,
  Fragment,
  createContext,
  useEffect,
} from "react"
import type { PropsWithChildren, Dispatch, SetStateAction } from "react"
import { useViewer } from "common/hooks/data/useViewer"

type NotificationKind = "error" | "success" | "info" | "warning" | "loading"

interface NotificationProps {
  id?: string
  type: NotificationKind
  title: string
  message?: string
  closable?: boolean
  duration?: number
}

interface NotificationContextValue {
  notifications: NotificationProps[]
  setNotifications: Dispatch<SetStateAction<NotificationProps[]>>
}

interface NotificationProviderProps {
  value?: NotificationProps[]
}

const NotificationsContext = createContext<NotificationContextValue>({
  notifications: [],
  setNotifications: () => {},
})

export const NotificationsProvider = ({
  value,
  children,
}: PropsWithChildren<NotificationProviderProps>) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  const notificationsContextValue = useMemo(
    () => ({ notifications, setNotifications }),
    [notifications, setNotifications],
  )

  const { isEditor } = useViewer()

  useEffect(() => setNotifications(isEditor && value ? value : []), [value, isEditor])

  return (
    <NotificationsContext.Provider value={notificationsContextValue}>
      {children}
    </NotificationsContext.Provider>
  )
}

const withId = (notification: NotificationProps) => {
  return {
    ...notification,
    id: nanoid(),
  }
}

export const useNotifications = () => {
  const { notifications, setNotifications } = useContext(NotificationsContext)

  return useMemo(
    () => ({
      clear: () => setNotifications([]),
      push: (notification: NotificationProps) =>
        setNotifications((prevNotifications) => [...prevNotifications, withId(notification)]),
      set: (nextNotifications: NotificationProps | NotificationProps[]) => {
        if (Array.isArray(nextNotifications)) {
          setNotifications(nextNotifications.map((n) => withId(n)))
        } else {
          setNotifications([withId(nextNotifications)])
        }
      },
      get: () => notifications,
    }),
    [notifications, setNotifications],
  )
}

export const NotificationsContainer = () => {
  const { notifications } = useContext(NotificationsContext)
  return (
    <div
      aria-live="assertive"
      className="fixed left-1/2 top-0 z-10 flex max-w-md -translate-x-1/2 flex-col items-center space-y-4 px-4 py-6 sm:p-6"
    >
      {notifications.map((notification, index) => (
        <Notification {...notification} key={notification.id || index} />
      ))}
    </div>
  )
}

// This hook is used to display notifications from query strings, returning
// an array of notifications that can be passed to the notifications context.
// The query strings used to display a notification are:
// - notification: the message to display
// - notificationType: the type of notification to display (based on the NotificationKind enum)
export const useNotificationsFromQueryStrings = (): NotificationProps[] => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const title = search.get("notification")
    const type = search.get("notificationType")

    if (title) {
      setNotifications([
        {
          type: type as NotificationKind,
          title,
        },
      ])
    }
  }, [])

  return notifications
}

export const Notification = ({
  type,
  title,
  message,
  closable = true,
  duration,
}: NotificationProps) => {
  const t = useTranslations("components.notification")
  const [visible, setVisible] = useState(true)
  const onClose = useCallback(() => setVisible(false), [setVisible])

  useTimeoutEffect(onClose, duration)

  return (
    <Transition
      appear={true}
      show={visible}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <section
        role="status"
        className="inline-flex max-w-md rounded-md border border-gray-50 bg-white shadow-lg"
      >
        <div
          className={clsx("flex-1 rounded-l-md border-l-8 py-4 pl-3 pr-5", {
            "border-red-500": type === "error",
            "border-yellow-500": type === "warning",
            "border-green-600": type === "success",
            "border-blue-600": type === "info",
            "border-gray-200": type === "loading",
          })}
        >
          <div className="flex grow items-center gap-3">
            <div className="pt-0.5">
              {type === "error" && <ExclamationTriangleIcon className={`h-5 w-5 text-red-500`} />}
              {type === "warning" && (
                <ExclamationTriangleIcon className={`h-5 w-5 text-yellow-500`} />
              )}
              {type === "success" && <CheckCircleIcon className={`h-5 w-5 text-green-600`} />}
              {type === "info" && <InformationCircleIcon className={`h-5 w-5 text-blue-600`} />}
              {type === "loading" && (
                <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-50 border-l-gray-200 border-t-gray-200" />
              )}
            </div>
            <div className={clsx("grow space-y-1", { "text-gray-500": type === "loading" })}>
              {title && (
                <Heading level={6} weight="weak">
                  {title}
                </Heading>
              )}
              {message && <p className="text-3xs">{message}</p>}
            </div>
          </div>
        </div>
        {closable && (
          <div className="flex border-l-2 border-gray-50">
            <button
              type="button"
              className="rounded-none rounded-r-lg border border-transparent p-4 text-2xs font-medium text-gray-700 hover:text-gray-500"
              onClick={onClose}
            >
              {t("close")}
            </button>
          </div>
        )}
      </section>
    </Transition>
  )
}
