import { useEffect, PropsWithChildren, useState } from "react"
import { AmplitudeContext } from "common/contexts/AmplitudeContext"
import { amplitudeClient, buildNoOpClient } from "common/lib/AmplitudeUtils/ampliBuilder"

export interface AmplitudeContextStateContainerProps {
  regionCode: string
}

/**
 * AmplitudeContextStateContainer wraps an AmplitudeContext and provides
 * persistent state for it.
 *
 * This name is quite a mouthful -- alternative suggestions welcome
 */
export const AmplitudeContextStateContainer = (
  props: PropsWithChildren<AmplitudeContextStateContainerProps>,
) => {
  const [ampliClient, setAmpliClient] = useState(buildNoOpClient)
  const [amplitudeInitialised, setAmplitudeInitialised] = useState(false)

  useEffect(() => {
    if (!amplitudeInitialised) {
      const newClient = amplitudeClient(props.regionCode)
      setAmpliClient(newClient)
      if (newClient.isLoaded) setAmplitudeInitialised(true)
    }
  }, [setAmpliClient, amplitudeInitialised, setAmplitudeInitialised, props.regionCode])

  return <AmplitudeContext.Provider value={ampliClient}>{props.children}</AmplitudeContext.Provider>
}
