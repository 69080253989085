import { createContext } from "react"
import { buildNoOpClient } from "common/lib/AmplitudeUtils/ampliBuilder"

/**
 * Provides a React Context which contains an Amplitude client.
 * The client may be one of:
 * - A real client, initialised and ready to send events.
 * - A fake no-op client that silently discards events.
 *
 * The latter is used by default, and the real client will only be loaded
 * in certain conditions.
 */
export const AmplitudeContext = createContext(buildNoOpClient())
